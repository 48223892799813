@import "bootstrap/scss/functions";

// BDO Branding colors
$bdo-charcoal: #333333;
$bdo-pale-charcoal: #e7e7e7;
$bdo-red: #e81a3b;
$bdo-ocean: #027cab; // BDO style guide specifies #02a5e2, but that is not A11y complaint
$bdo-burgundy: #98002e;
$bdo-emerald: #218f8b;
$bdo-gold: #df8639;
$bdo-slate: #62798d; // BDO style guide specifies #657c91, but that is not A11y complaint
$bdo-hyperlink: #3174af; // BDO style guide specifies #428bca, but that is not A11y complaint

$unread-table-row-bg: #feeef0;

$bdo-success: #40825d;
$bdo-danger: #ad1931;
$bdo-warning: #c05702; // BDO style guide specifies #fd7e14, but that is not A11y complaint
$bdo-info: $bdo-slate;

$bdo-fs-h1: 32px;
$bdo-fs-h2: 24px;
$bdo-fs-h3: 20px;
$bdo-fs-h4: 18px;
$bdo-fs-h5: 16px;
$bdo-fs-h6: 16px;
$bdo-fs-body: 16px;
$bdo-fs-line-height: 26px;

// bootstrap variables
$primary: $bdo-red !default;
$secondary: $bdo-charcoal !default;

$success: $bdo-success !default;
$danger: $bdo-danger !default;
$warning: $bdo-warning !default;
$info: $bdo-info !default;
$help: $bdo-slate;

$link-color: $bdo-hyperlink !default;
$code-color: $bdo-charcoal !default;

$custom-theme-colors: (
  "accent": $bdo-ocean,
  "tertiary": $bdo-slate,
);

// scss-docs-start theme-bg-subtle-variables
$primary-bg-subtle: tint-color($primary, 95%) !default;
$secondary-bg-subtle: tint-color($secondary, 95%) !default;
$success-bg-subtle: tint-color($success, 95%) !default;
$info-bg-subtle: tint-color($info, 95%) !default;
$warning-bg-subtle: tint-color($warning, 95%) !default;
$danger-bg-subtle: tint-color($danger, 95%) !default;
// scss-docs-end theme-bg-subtle-variables

// Custom form fill colors
$form-radio-circle-fill-color: $primary !default;
$form-radio-circle-fill-color-hover: darken(
  $form-radio-circle-fill-color,
  12%
) !default;
$form-checkbox-fill-color: $primary !default;
$form-checkbox-fill-color-hover: darken(
  $form-checkbox-fill-color,
  12%
) !default;
$form-toggle-fill-color: $primary !default;
$form-toggle-fill-color-hover: darken($form-toggle-fill-color, 12%) !default;
// end Custom form fill colors

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2.5 !default;

$border-radius: 6px !default;
$datatable-cell-padding: 0.5rem 0.75rem !default;

$form-label-margin-bottom: 0.25rem !default;
$form-label-font-size: 14px !default;
$form-label-font-weight: 600 !default;

$transition-base: all 0.2s cubic-bezier(0.86, 0, 0.07, 1) !default;

// Override bootstrap grid breakpoints
// $grid-breakpoints: (
//   sm: 0,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
// ) !default;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1520px,
);

$zindex-dropdown: 900 !default;
$zindex-sticky: 920 !default;
$zindex-fixed: 930 !default;
$zindex-offcanvas-backdrop: 940 !default;
$zindex-offcanvas: 945 !default;
$zindex-modal-backdrop: 950 !default;
$zindex-modal: 955 !default;
$zindex-popover: 970 !default;
$zindex-tooltip: 980 !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: #fff !default;
$body-color: #1f1f1f !default;
$body-color-secondary: #6f6f6f;
$body-text-align: null !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$enable-negative-margins: true !default;
$negative-spacers: if(
  $enable-negative-margins,
  negativify-map($spacers),
  null
) !default;
// scss-docs-end spacer-variables-maps

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:
  "Proxima Nova",
  "Open Sans",
  system-ui,
  -apple-system,
  "Segoe UI",
  Roboto,
  "Helvetica Neue",
  Arial,
  "Noto Sans",
  "Liberation Sans",
  sans-serif,
  "Apple Color Emoji",
  "Segoe UI Emoji",
  "Segoe UI Symbol",
  "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: 14px !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: 400 !default;
$font-weight-light: 400 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 600 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 600 !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.25 !default;
$line-height-lg: 2 !default;

$h1-font-size: $bdo-fs-h1; //$font-size-base * 1.5 !default;
$h2-font-size: $bdo-fs-h2; //$font-size-base * 1.25 !default;
$h3-font-size: $bdo-fs-h3; //$font-size-base * 1.15 !default;
$h4-font-size: $bdo-fs-h4; //$font-size-base * 1 !default;
$h5-font-size: $bdo-fs-h5; //$font-size-base * 1 !default;
$h6-font-size: $bdo-fs-h6; //$font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: $font-weight-semibold !default;
$headings-line-height: 1 !default;
$headings-color: null !default;
// scss-docs-end headings-variables

//Radio Button Theming
// $form-check-input-border-radius:          .25em !default;
// $form-check-radio-border-radius:          50% !default;

//Focus blurs
$input-focus-border-color: var(--bs-primary) !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color-opacity: 0.1 !default;
$input-btn-focus-color: rgba(
  var(--bs-primary-rgb),
  $input-btn-focus-color-opacity
) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 $input-btn-focus-blur $input-btn-focus-width
  $input-btn-focus-color !default;
$input-btn-padding-y: 8px !default;
$input-btn-padding-x: 22px !default;

// Fully rounded buttons, etc.
// $btn-border-radius: 50px !default;
// $progress-border-radius: 50px !default;

// scss-docs-start progress-variables
$progress-height: 1.5rem !default;
// scss-docs-end progress-variables

// scss-docs-start form-input-variables
$input-padding-y: 0.75rem !default;
$input-padding-x: 0.75rem !default;
$input-font-size: 14px !default;
// scss-docs-end form-input-variables

$alert-margin-bottom: 0 !default;

// Configuration
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// Optional Sass map overrides here
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
