@import "../styles/helpers/_bootstrap-overrides/theming";

.toast-container {
  position: fixed;

  // Put the toasts on the bottom right,
  // moving up as new toasts are added
  bottom: 16px;
  display: flex;
  flex-direction: column-reverse;

  right: 0px;
  text-align: right;
  //font-family: $Font-MyriadPro-Regular;
  z-index: 1100;

  strong {
    //font-family: $Font-MyriadPro-Semibold;
  }

  button {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
    border-radius: 50%;
    height: 2.357rem;
    width: 2.357rem;

    margin: -12px;
  }

  .Success {
    background-color: var(--bs-success-bg-subtle) !important;
    border: 1px solid var(--bs-success-border-subtle) !important;

    button:hover {
      background-color: rgba($success, 0.1);
    }

    .pi {
      color: $success;
    }
  }

  .Error {
    background-color: var(--bs-danger-bg-subtle) !important;
    border: 1px solid var(--bs-danger-border-subtle) !important;

    button:hover {
      background-color: rgba($danger, 0.1);
    }

    .pi {
      color: $danger;
    }
  }

  .Busy {
    background-color: var(--bs-info-bg-subtle) !important;
    border: 1px solid var(--bs-info-border-subtle) !important;

    button:hover {
      background-color: rgba($danger, 0.1);
    }

    .pi {
      color: $info;
    }
  }

  .pi {
    font-size: 20px;

    &.pi-times {
      font-size: 16px;
    }
  }

  .toast-wrapper {
    width: 400px;
    max-width: calc(100vw - 32px);
    background-color: white;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 16px;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: var(--bs-border-radius);
  }

  .toast {
    text-align: left;
    color: var(--bs-body-color);
    min-height: 71px;

    padding: 16px;
    border-radius: var(--bs-border-radius);
  }
}
