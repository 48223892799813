// theming overrides
[data-bs-theme="dark"] {
  --bs-code-color: var(--bs-body-color);
  --bs-link-color: #428bca;
  --bs-link-hover-color: #397ab1;
  --bs-link-color-rgb: 66, 139, 202;
  --bs-link-hover-color-rgb: 57, 122, 177;
  --bs-secondary-rgb: var(--bs-secondary-color-rgb);

  .text-secondary,
  .link-secondary,
  .btn-outline-secondary {
    color: var(--bs-secondary-color) !important;
  }
  .btn-outline-secondary {
    border-color: var(--bs-secondary-color) !important;
  }
  .btn-outline-secondary:hover {
    border-color: rgba(var(--bs-secondary-color-rgb), 0.6) !important;
    color: var(--bs-emphasis-color) !important;
  }
  .link-secondary:hover {
    color: rgba(var(--bs-secondary-color-rgb), 0.6) !important;
    text-decoration-color: rgba(var(--bs-secondary-color-rgb), 0.6) !important;
  }

  .required-indicator,
  .text-danger,
  .link-danger {
    color: var(--bs-danger-text-emphasis) !important;
  }
  .p-chips.border.border-danger .p-chips-multiple-container,
  .border-danger {
    border-color: var(--bs-danger-text-emphasis) !important;
  }

  .text-warning {
    color: var(--bs-warning-text-emphasis) !important;
  }

  .text-info {
    color: var(--bs-info-text-emphasis) !important;
  }
}

.nav-pills {
  --bs-nav-pills-link-active-bg: var(--bs-secondary-bg);
}

.nav-link:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-primary-rgb), 0.25);
}

.link-primary:hover,
.link-primary:focus {
  color: RGBA(
    var(--bs-primary-hover-rgb),
    var(--bs-link-opacity, 1)
  ) !important;
  text-decoration-color: RGBA(
    var(--bs-primary-hover-rgb),
    var(--bs-link-underline-opacity, 1)
  ) !important;
}
