.nav,
.navbar {
  --bs-nav-link-color: var(--bs-secondary-color) !important;
  --bs-nav-link-hover-color: rgba(
    var(--bs-secondary-color-rgb),
    0.6
  ) !important;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;

  .nav-text,
  .nav-link {
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  }

  .nav-link {
    &:focus-visible {
      color: inherit;
    }

    &:active {
      color: var(--bs-nav-link-hover-color);
    }
  }

  /* use text-shadow since font-weight changing updates the width */
  @supports (font-variation-settings: "GRAD" 150) {
    .nav-link.active {
      font-variation-settings: "GRAD" 150;
    }
  }
  @supports not (font-variation-settings: "GRAD" 150) {
    .nav-link.active {
      text-shadow:
        -0.06ex 0 0 currentColor,
        0.06ex 0 0 currentColor;
    }
  }

  .nav-link.active {
    &:active {
      color: var(--bs-nav-pills-link-active-color);
    }
  }
}
