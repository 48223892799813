.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: var(--bs-border-radius);
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 0 0;
  border-radius: var(--bs-border-radius);
}
.p-datatable .p-datatable-header {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 1px 0 1px 0;
  padding: $datatable-cell-padding;
  font-weight: 700;
}
.p-datatable .p-datatable-footer {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 0 0 1px 0;
  padding: $datatable-cell-padding;
  font-weight: 700;
}
.p-datatable-table .p-datatable-thead {
  position: sticky;
  top: 0px;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: $datatable-cell-padding;
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
  transition:
    box-shadow 0.2s,
    background-color 0.2s;
  // position: relative;  //commenting out because this is higher precedence than needed sticky position for pFrozenColumn's
  white-space: nowrap;
}
.p-datatable .p-datatable-thead > tr > th {
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
}
.p-datatable .p-datatable-thead > tr > th:after {
  content: "";
  width: 1px;
  height: 50%;
  background: var(--bs-secondary-bg);
  opacity: 0.5;
  position: absolute;
  display: inline-block;
  right: 0;
}
.p-datatable .p-datatable-thead > tr > th.last-child:after,
.p-datatable .p-datatable-thead > tr > th:last-child:after {
  opacity: 0;
}
.p-datatable .p-datatable-tfoot > tr > th {
  text-align: left;
  padding: $datatable-cell-padding;
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: $datatable-cell-padding;
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: var(--bs-body-color);
  background: var(--bs-body-bg);
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--bs-body-color);
  margin-left: 0.5rem;
}
p-sorticon {
  display: inline-flex;
}
.p-datatable .p-sortable-column .p-icon-wrapper {
  display: inline-flex !important;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: var(--bs-body-bg);
  background: var(--bs-secondary-color);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: var(--bs-secondary-bg);
}
.p-datatable
  .p-sortable-column:not(.p-highlight):hover
  .p-sortable-column-icon {
  color: var(--bs-body-color);
}
.p-datatable .p-sortable-column.p-highlight {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: var(--bs-body-color);
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: var(--bs-secondary-bg);
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: var(--bs-body-color);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: $input-focus-box-shadow;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  padding: $datatable-cell-padding;
  background-color: inherit;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: var(--bs-body-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: $transition-base;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: var(--bs-body-color);
  border-color: transparent;
  background: var(--bs-tertiary-bg);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: $btn-focus-box-shadow;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid tint-color($secondary, 90%);
  outline-offset: 0.15rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: tint-color($secondary, 95%);
  color: shade-color($secondary, 20%);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 tint-color($secondary, 95%);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 tint-color($secondary, 95%);
}
.p-datatable.p-datatable-hoverable-rows
  .p-datatable-tbody
  > tr:not(.p-highlight):hover {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
}
.p-datatable .p-column-resizer-helper {
  background: $secondary;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: var(--bs-tertiary-bg);
}
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-thead,
.p-datatable.p-datatable-scrollable
  > .p-datatable-wrapper
  > .p-datatable-table
  > .p-datatable-tfoot {
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(var(--bs-body-color-rgb), 0.03);
}
.p-datatable .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: rgba(var(--bs-body-color-rgb), 0.03);
  color: var(--bs-body-color);
}
.p-datatable
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler {
  color: var(--bs-emphasis-color);
}
.p-datatable
  .p-datatable-tbody
  > tr:nth-child(even).p-highlight
  .p-row-toggler:hover {
  color: var(--bs-emphasis-color);
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

/*Frozen Column Helpers*/
.p-datatable-table {
  .p-datatable-thead,
  .p-datatable-tbody,
  .p-datatable-tfoot {
    /* Gradient to the right of frozen columns */
    .p-frozen-column:has(+ th):not(:has(+ .p-frozen-column)),
    .p-frozen-column:has(+ td):not(:has(+ .p-frozen-column)) {
      &:after {
        content: "";
        height: 100%;
        position: absolute;
        z-index: 5;

        top: 0;
        right: 0;
        bottom: 0;
        width: 7px;
        border: 0;
        background: linear-gradient(
          to right,
          var(--bs-border-color-translucent) 0%,
          var(--bs-border-color-translucent) 20%,
          var(--bs-border-color-translucent) 20%,
          rgba(255, 255, 255, 1) 100%
        ) !important;
      }
    }
    /*Gradient for even (striped) rows */
    tr:nth-child(even) .p-frozen-column:has(+ th):not(:has(+ .p-frozen-column)),
    tr:nth-child(even)
      .p-frozen-column:has(+ td):not(:has(+ .p-frozen-column)) {
      &:after {
        background: linear-gradient(
          to right,
          var(--bs-border-color-translucent) 0%,
          var(--bs-border-color-translucent) 20%,
          var(--bs-border-color-translucent) 20%,
          rgba(239, 239, 239, 1) 100%
        ) !important;
      }
    }

    /* Gradient to the left of frozen columns */
    th:not(.p-frozen-column) + .p-frozen-column,
    td:not(.p-frozen-column) + .p-frozen-column {
      &:before {
        content: "";
        height: 100%;
        position: absolute;
        z-index: 5;

        top: 0;
        left: 0;
        bottom: 0;
        width: 7px;
        border: 0;
        background: linear-gradient(
          to left,
          var(--bs-border-color-translucent) 0%,
          var(--bs-border-color-translucent) 20%,
          var(--bs-border-color-translucent) 20%,
          rgba(255, 255, 255, 1) 100%
        ) !important;
      }
    }
  }
}
