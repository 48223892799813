pre + h1 {
  margin-top: 1.75rem;
}

pre + h2 {
  margin-top: 1.5rem;
}

pre + h3 {
  margin-top: 1.25rem;
}

pre + h4 {
  margin-top: 1rem;
}
